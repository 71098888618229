import React from "react";
import Select from "react-select";
import Footer from "../element/Footer";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NavBar from "../element/NavBar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import HTMLReactParser from "html-react-parser";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MapKey from "../mapkey/Mapkey";

const Jobseekers = () => {
  const [filterItem, setFilterItem] = useState({
    keyword: "",
    location: "",
    skills: "",
    total_exp: "",
    exp_salary: "",
  });
  const [loading, setLoading] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [experienceData, setExperienceData] = useState([]);
  const [salaryData, setSalaryData] = useState([]);
  const [skillList, setSkillList] = useState([]);
  // const [locationData, setLocationData] = useState([]);
  const [searchButton, setSearchButton] = useState(false);
  const [searchData, setSearchData] = useState([]);

  const { slug } = useParams();
  const tokenKey = Cookies.get("tokenClient");
  // let mapKey = Cookies.get("mapKey");

  const navigate = useNavigate();

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(BaseApi + "/candidates/listing", null, {
        headers: {
          "Content-Type": "application/json",
          key: ApiKey,
          token: tokenKey,
        },
      });
      setLoading(false);
      setListingData(response.data.response.candidates);
      setSkillList(response.data.response.skills);
      setExperienceData(response.data.response.experience);
      setSalaryData(response.data.response.salary);
    } catch (error) {
      setLoading(false);
      console.log("Could not get user data in profile page of favourite list");
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    // Check if the category_id is being changed
    setFilterItem((prevFilter) => ({
      ...prevFilter,
      [name]: value,
    }));

    console.log(filterItem);
  };
  const handleClick = async (e) => {
    var interest_skills = document.getElementsByName("skill");

    var skillArray = [];

    interest_skills.forEach((element) => {
      skillArray.push(element.value);
    });
    console.log(skillArray);
    const updatedData = {
      ...filterItem,
      skill: skillArray,
    };

    e.preventDefault();
    setSearchButton(true);
    setLoading(true);
    try {
      const response = await axios.post(
        "https://job-board-software.logicspice.com/job-board-script/api/candidates/listing",
        updatedData,
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      setSearchData(response.data.response.candidates);
      setLoading(false);
      console.log("Search filter data sent successfully");
    } catch (error) {
      setLoading(false);
      console.log("Couldn't send the search filter data!");
    }
  };

  function checkSkills(skills) {
    if (skills && Object.keys(skills).length > 0) {
      // If skills are present and not empty, return them
      return Object.entries(skills).map(([key, value]) => (
        <div key={key}>{value}</div>
      ));
    } else {
      // If skills are not present or empty, return "N/A"
      return <div>N/A</div>;
    }
  }

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 12;

  // Get current jobs to display based on pagination
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentData = searchButton
    ? searchData.slice(indexOfFirstJob, indexOfLastJob)
    : listingData.slice(indexOfFirstJob, indexOfLastJob);

  // Function to handle pagination page change
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/employerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  const handleReset = () => {
    // getData();
    // setFilterItem({
    //   ...filterItem,
    //   keyword: "",
    //   location: "",
    //   skills: "",
    //   total_exp: "",
    //   exp_salary: "",
    // });
    window.location.reload()
  };

  // location code

  const [autocompleteService, setAutocompleteService] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    // Load Google Maps AutocompleteService after component mounts
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${MapKey}&libraries=places`;
    script.onload = () => {
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      );
      console.log(autocompleteService);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleLocationChange = (e) => {
    const { value } = e.target;
    setSuggestionTaken(false);
    if (value == "") {
      setSuggestionTaken(true);
    }

    setFilterItem((prevFilter) => ({
      ...prevFilter,
      location: value,
    }));

    if (autocompleteService) {
      // Call Google Maps Autocomplete API
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: ["(cities)"], // Restrict to cities if needed
        },
        (predictions, status) => {
          if (status === "OK" && predictions) {
            setSuggestions(
              predictions.map((prediction) => prediction.description)
            );
          } else {
            setSuggestions([]);
          }
        }
      );
    }
    if (filterItem.location === "") {
      setSuggestions([]);
    }
  };
  const [suggestionTaken, setSuggestionTaken] = useState(false);

  const handleSuggestionClick = (suggestion) => {
    // Update the input value with the clicked suggestion
    handleLocationChange({ target: { name: "location", value: suggestion } });

    setSuggestionTaken(true);
    // Clear the suggestions
    setSuggestions([]);
    console.log(filterItem);
  };

  return (
    <>
      <NavBar />
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <div className="privacyPolicy jobseekerListing">
            <div className="text-center PPSection1">
              <h1 className="">Jobseekers</h1>
              <h6 className="text-muted fw-normal">
                {" "}
                <Link to="/" style={{ color: "grey" }}>
                  Home
                </Link>{" "}
                /Jobseekers
              </h6>
            </div>
            <div className="jobseekerLowerPart container">
              <div className="row">
                <div className="col-md-3">
                  <div className="cardHead">
                    <p>Jobseeker Search</p>
                  </div>
                  <div className="cardBody">
                    <form className="jobseekerListingForm">
                      <div className="mb-2 mt-2">
                        <div class="mb-3">
                          <input
                            type="text"
                            id="form3Example1"
                            className="form-control"
                            name="keyword"
                            placeholder="Keyword"
                            value={filterItem.keyword}
                            onChange={handleChange}
                          />
                        </div>

                        <div class="mb-3 position-relative">
                          <input
                            type="text"
                            id="form3Example1"
                            className="form-control"
                            name="location"
                            value={filterItem.location}
                            placeholder="Location"
                            onChange={handleLocationChange}
                          />
                          {suggestions.length > 0 && (
                            <div
                              className="suggestionsSmall"
                              style={{ display: suggestionTaken ? "none" : "" }}
                            >
                              <ul className="locationDropdown">
                                {suggestions.map((suggestion, index) => (
                                  <div key={index} className="suggestion-item">
                                    <li
                                      onClick={() =>
                                        handleSuggestionClick(suggestion)
                                      }
                                    >
                                      <div className="eachLocation">
                                        <div className="locationIcon">
                                          <LocationOnIcon fontSize="small" />
                                        </div>{" "}
                                        <div className="locationSuggestion">
                                          {suggestion}
                                        </div>
                                      </div>
                                    </li>
                                  </div>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                        <div class="mb-3">
                          <Select
                            // defaultValue={[colourOptions[2], colouptions[3]]}
                            isMulti
                            isSearchable
                            name="skill"
                            options={skillList.map((i) => ({
                              value: i.name,
                              label: i.name,
                            }))}
                            className="basic-multi-select jobseekerListingSelect"
                            classNamePrefix="select"
                            placeholder="Select Skills"
                          />
                        </div>
                        <div class="mb-3">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            name="total_exp"
                            value={filterItem.total_exp}
                            onChange={handleChange}
                          >
                            <option selected value="">
                              Choose Experience
                            </option>
                            {experienceData.map((i) => {
                              return (
                                <option key={i.id} value={i.id}>
                                  {i.val}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div class="mb-3">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            name="exp_salary"
                            value={filterItem.exp_salary}
                            onChange={handleChange}
                          >
                            <option selected value="">
                              Choose Salary
                            </option>
                            {salaryData.map((i) => {
                              return (
                                <option key={i.id} value={i.id}>
                                  {i.val}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <button
                          type="button"
                          className="btn btn-primary button1"
                          onClick={handleClick}
                        >
                          SEARCH
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary button1"
                          onClick={() => handleReset()}
                        >
                          RESET
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                {loading ? (
                  <div className="loader-container"></div>
                ) : (
                  <>
                    <div className="col-md-9">
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Skills</th>
                            <th>Contact Number</th>
                            <th>Experience</th>
                          </tr>
                        </thead>
                        <tbody>
                          {searchButton ? (
                            currentData.length > 0 ? (
                              currentData.map((i) => {
                                return (
                                  <>
                                    <tr>
                                      <td>
                                        <Link
                                          to={`/candidates/profile/${i.slug}`}
                                        >
                                          {i.first_name} {i.last_name}
                                        </Link>
                                      </td>
                                      <td>
                                        {checkSkills(i.skills)}
                                        {/* {Object.entries(i.skills).map(([key, value]) => {
                                  return { value };
                                })}  */}
                                      </td>
                                      <td>
                                        {i.contact != null ? i.contact : "N/A"}
                                      </td>
                                      <td>
                                        {i.total_exp != ""
                                          ? i.total_exp
                                          : "N/A"}
                                      </td>
                                    </tr>{" "}
                                  </>
                                );
                              })
                            ) : (
                              <div className="col-12">
                                <h3 className="text-center">
                                  There are no jobseekers matching your search
                                  criteria.
                                </h3>
                                <h6 className="text-muted text-center mb-5 mt-3">
                                  Please search with other options.
                                </h6>
                              </div>
                            )
                          ) : listingData.length > 0 ? (
                            currentData.map((i) => {
                              return (
                                <>
                                  <tr>
                                    <td>
                                      <Link
                                        to={`/candidates/profile/${i.slug}`}
                                      >
                                        {i.first_name} {i.last_name}
                                      </Link>
                                    </td>
                                    <td>
                                      {checkSkills(i.skills)}
                                      {/* {Object.entries(i.skills).map(([key, value]) => {
                                return { value };
                              })}  */}
                                    </td>
                                    <td>
                                      {i.contact != null ? i.contact : "N/A"}
                                    </td>
                                    <td>
                                      {i.total_exp != "" ? i.total_exp : "N/A"}
                                    </td>
                                  </tr>{" "}
                                </>
                              );
                            })
                          ) : (
                            <div className="col-12">
                              <h3 className="text-center">
                                There are no jobseekers matching for your search
                                criteria
                              </h3>
                              <h6 className="text-muted text-center mb-5 mt-3">
                                Please search with other options
                              </h6>
                            </div>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/* Custom Pagination */}
                    <div className="blogPagination">
                      <p className="text-muted paginationDetail">
                        No. of posts {indexOfFirstJob + 1}-
                        {Math.min(indexOfLastJob, searchData.length || listingData.length)} of{" "}
                        {searchButton? searchData.length : listingData.length}
                      </p>
                      <div className="blogPaginationButtons">
                        <button
                          className="navButton1"
                          disabled={currentPage === 1}
                          onClick={() => handlePageChange(currentPage - 1)}
                        >
                          Prev
                        </button>
                        <button
                          className="navButton1"
                          disabled={
                            searchButton
                              ? indexOfLastJob >= searchData.length
                              : indexOfLastJob >= listingData.length
                          }
                          onClick={() => handlePageChange(currentPage + 1)}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default Jobseekers;
