import React, { useEffect, useState } from "react";
import Footer from "../element/Footer";
import JSSidebar from "./JSSidebar";
import NavBar from "../element/NavBar";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import HTMLReactParser from "html-react-parser";
// import Swal from "sweetalert2";
import Cookies from 'js-cookie';


const JSMyProfile = () => {
  const [loading, setLoading] = useState(false);
  const [myProfile, setMyProfile] = useState([]);
  const [userDetail, setUserDetail] = useState([]);
  const [profileEducation, setProfileEducation] = useState([]);
  const [experience, setExperience] = useState([]);
  // const [photo, setPhoto] = useState({
  //   profile_image: "",
  // });
  const [oldCertificates, setOldCertificates] = useState([]);
  const [skill, setSkill] = useState([]);
  const tokenKey = Cookies.get("tokenClient");

  const navigate = useNavigate();


  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + "/candidates/myaccount",
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      setLoading(false);
      setMyProfile(response.data.response);
      setUserDetail(response.data.response.userdetail);
      setSkill(response.data.response.userdetail.skill);
      setProfileEducation(response.data.response.education);
      setExperience(response.data.response.experience);
      setOldCertificates(response.data.response.showOldImages);
      console.log(oldCertificates);
    } catch (error) {
      setLoading(false);
      console.log("Cannot get profile data");
    }
  };

  // const handleCertificateRemove = async (slug) => {
  //   try {
  //     const confirmationResult = await Swal.fire({
  //       title: "Delete Certificate?",
  //       text: "Do you want to delete this certificate?",
  //       icon: "question",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes",
  //       cancelButtonText: "No",
  //     });
  //     if (confirmationResult.isConfirmed) {
  //       const response = await axios.post(
  //         BaseApi + `/candidates/deleteCertificate/${slug}`,
  //         null, // Pass null as the request body if not required
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             key: ApiKey,
  //             token: tokenKey,
  //           },
  //         }
  //       );
  //       getData();
  //       Swal.fire({
  //         title: "Certificate deleted successfully!",
  //         icon: "success",
  //         confirmButtonText: "Close",
  //       });
  //       console.log(response);
  //     }
  //   } catch (error) {
  //     Swal.fire({
  //       title: "Could not delete certificate. Please try after some time!",
  //       icon: "error",
  //       confirmButtonText: "Close",
  //     });
  //     console.log("Cannot delete certificate");
  //   }
  // };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/jobseekerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  return (
    <>
      <NavBar />
      <div className="container changeLogo">
        <div className="row">
          <div className="col-lg-3">
            <JSSidebar />
          </div>
          {loading ? (
            <div className="loader-container"></div>
          ) : (
            <>
              <div
                className="col-lg-9 mb-5"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div class="mx-3 PageHeader">
                  <div class="TopHadding">
                    <h3>
                      <i>
                        <img
                          src="/Images/jobseekerSide/user-icon.png"
                          alt="img"
                        />{" "}
                      </i>
                      My Profile
                    </h3>
                  </div>
                  <div class="JobseekerProfileBx">
                    <div class="JobseekerProfileTopBx">
                      <h3>
                        <i>
                          <img
                            src="/Images/jobseekerSide/profile-icon.png"
                            alt="icon"
                          />
                        </i>
                        <span>Profile</span>
                      </h3>
                      <span class="EditProfileTag">
                        <Link to="/candidates/editprofile">
                          <i class="fa fa-pencil" aria-hidden="true"></i> Edit
                        </Link>
                      </span>
                    </div>

                    <div class="JobseekerProfileDetails">
                      <div class="JobseekerProfileBxDetailTop">
                        <div class="JobseekerProfileImg">
                          <img src={userDetail.profile_image} alt="img" />
                        </div>
                        <h3>
                          {userDetail.first_name} {userDetail.last_name}
                        </h3>
                      </div>
                      <div class="ProfileDetails">
                        <ul>
                          <li>
                            <i class="fa fa-phone" aria-hidden="true"></i>
                            <span>{userDetail.contact}</span>
                          </li>
                          <li class="">
                            <i class="fa fa-envelope-o" aria-hidden="true"></i>
                            <span>{userDetail.email_address}</span>
                          </li>
                          <li class="full-width">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            <span>{userDetail.location}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="JobseekerProfileBx">
                    <div class="JobseekerProfileTopBx">
                      <h3>
                        <i>
                          <img
                            src="/Images/jobseekerSide/Education-icon.png"
                            alt="icon"
                          />
                        </i>
                        <span>Education</span>
                      </h3>
                      <span class="EditProfileTag">
                        <Link to="/candidates/editEducation">
                          <i class="fa fa-pencil" aria-hidden="true"></i> Edit
                        </Link>
                      </span>
                    </div>

                    <div class="JobseekerEducationDetails">
                      {profileEducation.map((i) => {
                        return (
                          <>
                            <ul>
                              <li>
                                I have Passed {i.course_name} in {i.basic_year}{" "}
                                in from {i.basic_university}.
                              </li>
                            </ul>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <div class="JobseekerProfileBx">
                    <div class="JobseekerProfileTopBx">
                      <h3>
                        <i>
                          <img
                            src="/Images/jobseekerSide/Experience-icon.png"
                            alt="icon"
                          />
                        </i>
                        <span>Experience</span>
                      </h3>
                      <span class="EditProfileTag">
                        <Link to="/candidates/editExperience">
                          <i class="fa fa-pencil" aria-hidden="true"></i> Edit
                        </Link>
                      </span>
                    </div>

                    <div class="JobseekerEducationDetails">
                      {experience.map((i) => {
                        return (
                          <>
                            <ul>
                              <li>
                                I have worked as a {i.role} with{" "}
                                {i.company_name} since {i.from_year} to{" "}
                                {i.to_year}{" "}
                              </li>
                              <li>Industry: {i.industry}</li>
                              <li>Functional area: {i.functional_area}</li>
                              <li>Role: {i.role}</li>
                            </ul>
                            -----------------------------------------------
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <div class="JobseekerProfileBx">
                    <div class="JobseekerProfileTopBx">
                      <h3>
                        <i>
                          <img
                            src="/Images/jobseekerSide/Skills-icon.png"
                            alt="icon"
                          />
                        </i>
                        <span>Skills</span>
                      </h3>
                      <div class="AddProfileTag">
                        <Link to="/candidates/editprofile">
                          <i>
                            <img
                              src="/Images/jobseekerSide/AddIcon.png"
                              alt="icon"
                            />
                          </i>
                          <span> ADD</span>
                        </Link>
                      </div>
                    </div>

                    <div class="JobseekerSkillsAdd">
                      {Object.values(skill).map((value) => {
                        return (
                          <>
                            <div class="SkillsAddBxMain m-2">
                              <ul>
                                <li>
                                  <span>{value}</span>
                                  {/* <Link class="RemoveSkills">
                                    <img
                                      src="/Images/jobseekerSide/RemoveIcon.png"
                                      alt="icon"
                                    />
                                  </Link> */}
                                </li>
                                {/* <li>
                            <span>HR, Production Engineer</span>
                            <Link class="RemoveSkills">
                              <img
                                src="/Images/jobseekerSide/RemoveIcon.png"
                                alt="icon"
                              />
                            </Link>
                          </li> */}
                              </ul>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>

                  {/* <div class="JobseekerProfileBx">
                    <div class="JobseekerProfileTopBx">
                      <h3>
                        <i>
                          <img
                            src="/Images/jobseekerSide/Sertifications-icon.png"
                            alt="icon"
                          />
                        </i>
                        <span>Licenses & Certifications</span>
                      </h3>
                      <div class="AddProfileTag">
                        <Link>
                          <i>
                            <img
                              src="/Images/jobseekerSide/AddIcon.png"
                              alt="icon"
                            />
                          </i>
                          <span> ADD</span>
                        </Link>
                      </div>
                    </div>

                    <div class="JobseekerSkillsAdd">
                      <div class="SkillsAddBxMain">
                        <ul>
                          <li>
                            <span>Best Developer</span>
                            <Link class="RemoveSkills">
                              <img
                                src="/Images/jobseekerSide/RemoveIcon.png"
                                alt="icon"
                              />
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div> */}

                  <div class="JobseekerProfileBx">
                    <div class="JobseekerProfileTopBx">
                      <h3>
                        <i>
                          <img
                            src="/Images/jobseekerSide/profile-icon.png"
                            alt="icon"
                          />
                        </i>
                        <span>About Your Self</span>
                      </h3>
                      <span class="EditProfileTag">
                        <Link to="/candidates/editprofile">
                          <i class="fa fa-pencil" aria-hidden="true"></i> Edit
                        </Link>
                      </span>
                    </div>

                    <div class="JobseekerEducationDetails">
                      <p>
                        {userDetail.company_about
                          ? HTMLReactParser(userDetail.company_about)
                          : ""}
                      </p>
                    </div>
                  </div>

                  <div class="JobseekerProfileBx">
                    <div class="JobseekerProfileTopBx">
                      <h3>
                        <i>
                          <img
                            src="/Images/jobseekerSide/CurrentPlan-icon.png"
                            alt="icon"
                          />
                        </i>
                        <span>Current Plan</span>
                      </h3>
                    </div>

                    <div class="JobseekerEducationDetails">
                      <div class="CurrentPlanBx">
                        <label>
                          {myProfile.plan_name
                            ? myProfile.plan_name
                            : "No plan selected"}
                        </label>
                        <Link to="/plans/purchase" class="btn btn-primary">
                          UPGRADE PLAN
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div class="JobseekerProfileBx">
                    <div class="JobseekerProfileTopBx">
                      <h3>
                        <i>
                          <img
                            src="/Images/jobseekerSide/Certificates-icon.png"
                            alt="icon"
                          />
                        </i>
                        <span>CV Document/Certificates</span>
                      </h3>
                    </div>

                    <div class="JobseekerCertificatesDetails">
                      <ul>
                        {oldCertificates.map((i, index) => {
                          return (
                            <>
                              <li>
                                {/* <i
                                  class="fa-regular fa-circle-xmark jsprofileCross"
                                  onClick={() =>
                                    handleCertificateRemove(i.slug)
                                  }
                                ></i> */}
                                <i>
                                  <img
                                    className="JSmyProfileCertificateImage"
                                    src={i.image}
                                    alt="icon"
                                  />
                                </i>
                                <span>Certificate {index + 1}</span>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="myProfileLinks">
                  <Link className="myProfileEachButton button1">DELETE ACCOUNT</Link>
                  <Link className="myProfileEachButton button1" to="/candidates/changepassword">CHANGE PASSWORD</Link>
                </div>
                </div>
                
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default JSMyProfile;
