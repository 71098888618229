import React, { useEffect, useState } from "react";
import Footer from "../element/Footer";
import NavBar from "../element/NavBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ApiKey from "../api/ApiKey";
import BaseApi from "../api/BaseApi";
import Sidebar from "./Sidebar";
import Cookies from 'js-cookie';


const MailDetail = () => {
  const [loading, setLoading] = useState(false);
  const [mailDetail, setMailDetail] = useState([]);
  const [mailReceiver, setMailReceiver] = useState([]);
  const [mailSender, setMailSender] = useState([]);


  const { slug } = useParams();

  const navigate = useNavigate();

  const tokenKey = Cookies.get("tokenClient");

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/employerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + `/users/maildetail/${slug}`,
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      setLoading(false);
      setMailDetail(response.data.response.mails);
      setMailReceiver(response.data.response.mails.reciever)
      setMailSender(response.data.response.mails.sender)
      console.log(mailDetail);
    } catch (error) {
      setLoading(false);
      console.log("Cannot get mail detail of mail history page at employer side");
    }
  };



  return (
    <>
      <NavBar />
      <div className="container editProfile">
        {/* Reply Modal  */}
        <div
          class="modal fade"
          id="ReplyModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  Reply Mail
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div class="form-outline mb-5 mt-4 DashBoardInputBx">
                  <label class="form-label" for="form3Example3">
                    Subject <span className="RedStar">*</span>
                  </label>
                  <input
                    type="text"
                    id="form3Example3"
                    class="form-control"
                    placeholder="Subject"
                    // value={password.new_password}
                    // name="new_password"
                    // onChange={handleChange}
                  />
                </div>
                <div class="form-outline mb-5 DashBoardInputBx">
                  <label class="form-label" for="form3Example3">
                    Message <span className="RedStar">*</span>
                  </label>
                  <input
                    type="text"
                    id="form3Example3"
                    class="form-control"
                    placeholder="Message"
                    // value={password.new_password}
                    // name="new_password"
                    // onChange={handleChange}
                  />
                </div>
                <div class="form-outline mb-3 DashBoardInputBx">
                  <label for="formFile" class="form-label">
                    Multiple Images
                  </label>
                  <input class="form-control" type="file" id="formFile" />
                  <div id="emailHelp" class="form-text mt-2">
                    Select multiple file with Ctrl press, Supported File Types:
                    gif, jpg, jpeg, png, pdf, doc, docx (Max 5 images and Max.
                    10MB).
                  </div>
                </div>
                <button type="button" className="btn btn-primary button1">
                  SUBMIT
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          {loading ? (
            <div className="loader-container"></div>
          ) : (
            <>
              <div
                className="col-lg-9 mb-5 mailDetail"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div className="mx-3 d-flex mb-4 PageHeader">
                  <img src="/Images/employerSide/icon8color.png" alt="" />
                  <h3 className="">Mail Detail</h3>
                </div>
                <div className="mx-4 mb-5 mailDetailBx">
                  

                  <h5>Dear{" "}{mailReceiver.first_name}{" "}{mailReceiver.last_name},</h5>
                  <p>
                    An employer send you the mail on Job Board Portal Script.
                    Here is the detail:- <br />
                    Company name : {mailSender.company_name}
                    <br />
                    Email Address : {mailSender.email_address}<br />
                    Subject : {mailDetail.subject}<br />
                    Message : {mailDetail.message}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default MailDetail;
