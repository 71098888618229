import React, { useRef } from "react";
import NavBar from "../element/NavBar";
import CategoryCard from "../element/CategoryCard";
import BrowseJobToggler from "../element/BrowseJobToggler";
import JobCard from "../element/JobCard";
import MembershipCard from "../element/MembershipCard";
import Footer from "../element/Footer";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import Marquee from "react-fast-marquee";
import Cookies from "js-cookie";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import JobListingCard from "../element/JobListingCard";
import MapKey from "../mapkey/Mapkey";

const UserPage = () => {
  const [homePageSlogan, setHomePageSlogan] = useState();
  const [categoryListing, setCategoryListing] = useState([]);
  const [jobCardData, setJobCardData] = useState([]);
  const [membershipData, setMembershipData] = useState([]);
  const [topEmployer, setTopEmployer] = useState([]);
  const [bannerDetails, setBannerDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [base64Code, setBase64Code] = useState();
  const [announcementDetails, setAnnouncementDetails] = useState([]);
  const [importedJobs, setImportedJobs] = useState([]);

  const [location, setLocation] = useState("");
  // const [newLocation, setNewLocation] = useState("");
  const [radius, setRadius] = useState("");

  let newLocation = location.split(",");
  let finalLocation = newLocation[0];
  console.log(newLocation,"newlocation")
  console.log(finalLocation,"final");

  // useEffect(() => {
  //   setNewLocation(location.split(","));
  //   console.log(newLocation,"newlocation");
  // }, [location])
  
  const tokenKey = Cookies.get("tokenClient");
  // let mapKey = Cookies.get("mapKey");

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(BaseApi + "/home", {
        headers: {
          "Content-Type": "application/json",
          key: ApiKey,
          token: tokenKey,
        },
      });
      setLoading(false);

      // console.log(response.data.response)
      setHomePageSlogan(response.data.response.sloganText.slogan_text);
      setCategoryListing(response.data.response.categories_listing);
      setJobCardData(response.data.response.latestJobList);
      setMembershipData(response.data.response.plans_details);
      setTopEmployer(response.data.response.top_employer);
      setBannerDetails(response.data.response.bannerDetails);
      setAnnouncementDetails(response.data.response.announcementList);
      setImportedJobs(response.data.response.importjobs);
      // console.log(jobCardData);
    } catch (error) {
      setLoading(false);
      console.log("Cannot get home page data!");
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Check if the selected file is a .doc file
    if (file) {
      if (file.type === "application/msword" || file.name.endsWith(".doc")) {
        // Read the file using FileReader to get Base64 data
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result;
          setSelectedFile(file);
          setBase64Code(base64Data); // Assuming you have a state variable 'base64Code' to store the Base64 data
        };
        reader.readAsDataURL(file);
      } else {
        // Display an error message for file types other than .doc
        alert("Please select a .doc file (Microsoft Word document).");
      }
    }
  };
  // console.log(base64Code);

  useEffect(() => {
    getData();
  }, []);
  const handleRadiusChange = (e) => {
    const { name, value } = e.target;
    // Validate input to allow only digits and limit length to 1-3 digits
    const isValidInput = /^\d{0,3}$/.test(value);
    if (isValidInput) {
      setRadius(value);
    }
  };

  const [autocompleteService, setAutocompleteService] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    // Load Google Maps AutocompleteService after component mounts
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${MapKey}&libraries=places`;
    script.onload = () => {
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      );
      console.log(autocompleteService);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleLocationChange = (e) => {
    const { value } = e.target;
    setSuggestionTaken(false);
    if (value == "") {
      setSuggestionTaken(true);
    }

    setLocation(value);

    if (autocompleteService) {
      // Call Google Maps Autocomplete API
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: [], // Restrict to cities if needed
        },
        (predictions, status) => {
          if (status === "OK" && predictions) {
            setSuggestions(
              predictions.map((prediction) => prediction.description)
            );
          } else {
            setSuggestions([]);
          }
        }
      );
    }
    if (location === "") {
      setSuggestions([]);
    }
  };
  const [suggestionTaken, setSuggestionTaken] = useState(false);

  const handleSuggestionClick = (suggestion) => {
    // Update the input value with the clicked suggestion
    handleLocationChange({ target: { name: "location", value: suggestion } });

    setSuggestionTaken(true);
    // Clear the suggestions
    setSuggestions([]);
    console.log(location);
  };

  return (
    <>
      <NavBar />
      {/* {loading ? (
        <>
          <div className="loader-container"></div>
        </>
      ) : (
        <> */}
      <div>
        {/* First block of the home page */}

        <div className="sliderbody">
          <div className="container container">
            <div className="row">
              <div className="col-md-10 col-lg-7 userPageFirstCol">
                <div className="slidertext">
                  <h1>50,000 Jobs Available</h1>
                  <p>{homePageSlogan}</p>
                </div>
                <div className="searcharea">
                  <i>
                    <img src="/Images/searchicon.png" alt="" />
                  </i>
                  <div className="inputGrp1">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Job title or keyword"
                      aria-label="Search"
                      aria-describedby="search-addon"
                      value={keyword}
                      name="keyword"
                      onChange={(e) => {
                        setKeyword(e.target.value);
                      }}
                    />
                  </div>
                  <div className="inputGrp">
                    <i class="fa-solid fa-location-dot"></i>{" "}
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Location"
                      aria-label="Search"
                      aria-describedby="search-addon"
                      value={location}
                      name="location"
                      onChange={handleLocationChange}
                    />
                    {suggestions.length > 0 && (
                      <div
                        className="suggestionsSmall"
                        style={{ display: suggestionTaken ? "none" : "" }}
                      >
                        <ul className="locationDropdown">
                          {suggestions.map((suggestion, index) => (
                            <div key={index} className="suggestion-item">
                              <li
                                onClick={() =>
                                  handleSuggestionClick(suggestion)
                                }
                              >
                                <div className="eachLocation">
                                  <div className="locationIcon">
                                    <LocationOnIcon fontSize="small" />
                                  </div>{" "}
                                  <div className="locationSuggestion">
                                    {suggestion}
                                  </div>
                                </div>
                              </li>
                            </div>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="inputGrp inputGrpRadius">
                    <i class="fa-solid fa-circle-notch"></i>{" "}
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Radius (Miles)"
                      aria-label="Search"
                      aria-describedby="search-addon"
                      value={radius}
                      name="radius"
                      // onChange={(e) => {
                      //   setRadius(e.target.value);
                      // }}
                      onChange={handleRadiusChange}
                    />
                  </div>
                  {/* {keyword && (
                    <Link
                      to={`/joblisting/${keyword}`}
                      type="button"
                      className="btn btn-primary button1"
                    >
                      SEARCH
                    </Link>
                  )}
                  {location && radius && (
                    <Link
                      to={`/joblisting/${location}/${radius}`}
                      type="button"
                      className="btn btn-primary button1"
                    >
                      SEARCH
                    </Link>
                  )}
                  {location && radius === "" && (
                    <Link
                      to={`/joblisting/${location}`}
                      type="button"
                      className="btn btn-primary button1"
                    >
                      SEARCH
                    </Link>
                  )}
                  {radius && location === "" && (
                    <Link
                      to={`/joblisting/${radius}`}
                      type="button"
                      className="btn btn-primary button1"
                    >
                      SEARCH
                    </Link>
                  )}
                  {location === "" && radius === "" && keyword === "" && (
                    <Link
                      to="/joblisting"
                      type="button"
                      className="btn btn-primary button1"
                    >
                      SEARCH
                    </Link>
                  )} */}
                  {/* {keyword && (
                    <Link
                      to={`/joblisting?keyword=${keyword}`}
                      type="button"
                      className="btn btn-primary button1"
                    >
                      SEARCH
                    </Link>
                  )} */}
                  {(finalLocation != "" || radius != '' || keyword  != "" ) ? (
                    <Link
                      to={`/joblisting?keyword=${keyword}&location=${finalLocation}&radius=${radius}`}
                      type="button"
                      className="btn btn-primary button1"
                    >
                      SEARCH
                    </Link>
                  ): (                    
                  <Link
                    to="/joblisting"
                    type="button"
                    className="btn btn-primary button1"
                  >
                    SEARCH
                  </Link>
                  )}
                  {/* {finalLocation && radius === "" && (
                    <Link
                      to={`/joblisting?location=${newLocation}`}
                      type="button"
                      className="btn btn-primary button1"
                    >
                      SEARCH
                    </Link>
                  )} */}
                  {/* {radius && finalLocation === "" && (
                    <Link
                      to={`/joblisting?radius=${radius}`}
                      type="button"
                      className="btn btn-primary button1"
                    >
                      SEARCH
                    </Link>
                  )} */}
                  {/* {finalLocation === "" && radius === "" && keyword === "" && (
                    <Link
                      to="/joblisting"
                      type="button"
                      className="btn btn-primary button1"
                    >
                      SEARCH
                    </Link>
                  )} */}
                  <button
                    type="button"
                    onClick={handleFileUpload}
                    className="btn btn-primary button2"
                  >
                    UPLOAD CV
                  </button>
                  <input
                    type="file"
                    ref={fileInputRef}
                    accept=".doc"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>
                {/* <div className="searcharea locationRadiusSearch">
                  <i>
                    <img src="/Images/searchicon.png" alt="" />
                  </i>
                  <div className="inputGrp">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Location"
                      aria-label="Search"
                      aria-describedby="search-addon"
                      value={location}
                      name="location"
                      onChange={(e) => {
                        setLocation(e.target.value);
                      }}
                    />
                  </div>
                  <div className="inputGrp">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Radius"
                      aria-label="Search"
                      aria-describedby="search-addon"
                      value={radius}
                      name="radius"
                      onChange={(e) => {
                        setRadius(e.target.value);
                      }}
                    />
                  </div>
                
                  {location && radius && (
                    <Link
                      to={`/joblisting?location=${location}&&radius=${radius}`}
                      type="button"
                      className="btn btn-primary button1"
                    >
                      SEARCH
                    </Link>
                  )}
                  {location && radius === "" && (
                    <Link
                      to={`/joblisting?location=${location}`}
                      type="button"
                      className="btn btn-primary button1"
                    >
                      SEARCH
                    </Link>
                  )}
                  {radius && location === "" && (
                    <Link
                      to={`/joblisting?radius=${radius}`}
                      type="button"
                      className="btn btn-primary button1"
                    >
                      SEARCH
                    </Link>
                  )}
                  {location === "" && radius === "" && (
                    <Link
                      to="/joblisting"
                      type="button"
                      className="btn btn-primary button1"
                    >
                      SEARCH
                    </Link>
                  )}
                </div> */}
                {selectedFile && (
                  <>
                    <div className="mt-3 d-flex">
                      <p className="pt-2">Selected File: {selectedFile.name}</p>
                      {/* You can use the selectedFile here or show additional file information */}
                      <button
                        className="btn btn-outline-dark ms-2"
                        onClick={() => setSelectedFile()}
                      >
                        Delete
                      </button>
                    </div>
                  </>
                )}
                <div className="slidertext2">
                  <div className="jobs-clients-posted">
                    <h3>789</h3>
                    <h6 className="tehttp://localhost:3000/user/register/jobseekerxt-muted">
                      Jobs Posted
                    </h6>
                  </div>
                  <div className="jobs-clients-posted">
                    <h3>596</h3>
                    <h6 className="text-muted">Freelancers</h6>
                  </div>
                </div>
              </div>
              <div className="BannerImg">
                <img
                  className="layer"
                  data-speed="-20"
                  src="/Images/banner-img.png"
                  alt=""
                />
                <div className="BannerImg1 layer" data-speed="-8">
                  <img src="/Images/banner-userimg1.png" alt="" />
                </div>
                <div className="BannerImg3 layer" data-speed="8">
                  <img src="/Images/bannerimg1.png" alt="" />
                </div>
                <div className="BannerImg4 layer" data-speed="10">
                  <img src="/Images/bannerimg2.png" alt="" />
                </div>
                <div className="BannerImg2 layer" data-speed="-5">
                  <img src="/Images/banner-userimg2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Second block of the home page */}
        <section className="CategorySection">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-md-4 col-lg-4">
                <div className="CategoryBx">
                  <img src="/Images/CreateAnAccount.jpg" alt="" />
                  <h5 className="">Create An Account</h5>
                  <p>
                    Job seekers can create account with basic user information.
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-md-4 col-lg-4">
                <div className="CategoryBx">
                  <img src="/Images/SearchDesiredJob.jpg" alt="" />
                  <h5 className="">Search Desired Job</h5>
                  <p>
                    Job aspirants can search Jobs which will provide them with
                    the relevant result.
                  </p>
                </div>
              </div>
              <div className="col-xs-12 col-md-4 col-lg-4">
                <div className="CategoryBx">
                  <img src="/Images/SendYourResume.jpg" alt="" />
                  <h5 className="">Send Your Resume</h5>
                  <p>
                    Job seeker can apply for Job, which is a potential match for
                    their profile.
                  </p>
                </div>
              </div>
            </div>
            {categoryListing != "" && (
              <>
                <div className="subsecondblock">
                  <div className="LeftDotImg">
                    <img src="/Images/dotimg.png" alt="" />
                  </div>
                  <div className="RightDotImg">
                    <img src="/Images/dotimg.png" alt="" />
                  </div>
                  <h3 className="subsecondblockHeading">
                    Explore
                    <span className="textGradient">
                      {" "}
                      <span className="SubHaddingTxt">Categories </span>
                    </span>
                  </h3>
                  {/* {loading ? (
                <div className="loader-container"></div>
              ) : (
                <> */}
                  <div className="ExploreCategory">
                    <div className="row">
                      {categoryListing.map((i) => {
                        return (
                          <>
                            <div className="col-md-6 col-lg-3 HomeCategorysCardBx">
                              <CategoryCard
                                title={i.name}
                                image1="/Images/Back-1.png"
                                image2={i.image}
                                footer={i.sub_cat}
                                slug={i.slug}
                                id={i.id}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <Link
                      to="/allcategory"
                      type="button"
                      className="btn btn-primary button1"
                    >
                      EXPLORE ALL CATEGORIES
                    </Link>
                  </div>
                  {/* </>
              )} */}
                </div>
              </>
            )}
          </div>
        </section>

        {/* third block of the home page */}

        <div className="thirdBlock">
          <div className="container">
            <div className="thirdBlockData">
              <h3 className="BrowseJobsHadding">Browse Jobs</h3>
              <BrowseJobToggler />
            </div>
          </div>
        </div>

        {/* Fourth block of the home page */}
        {jobCardData != "" && (
          <>
            <section className="FeaturedJobsSection">
              <div className="container fourthBlock text-center">
                <h3 className="subsecondblockHeading">
                  Featured
                  <span className="textGradient">
                    {" "}
                    <span className="SubHaddingTxt">Jobs </span>
                  </span>
                </h3>
                <div className="FBitem">
                  <div className="row">
                    {jobCardData.slice(0, 6).map((i) => {
                      return (
                        <>
                          <div className="col-md-6 col-lg-4">
                            <JobCard
                              title={i.title}
                              min_salary={i.min_salary}
                              max_salary={i.max_salary}
                              min_exp={i.min_exp}
                              created={i.created}
                              logo={i.logo}
                              company_name={i.company_name}
                              work_type={i.work_type}
                              job_city={i.job_city}
                              slug={i.slug}
                              cat_slug={i.cat_slug}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                <Link to="/searchjob">
                  <button className="btn btn-primary">VIEW ALL JOBS</button>
                </Link>
                <div className="FBitem importJobsHomePage">
                  <div className="row">
                    {importedJobs.map((i) => {
                      return (
                        <>
                          <div className="col-md-6 col-lg-4">
                            <JobListingCard
                              category={i.category}
                              city={i.city}
                              company={i.company}
                              country={i.country}
                              date={i.date}
                              description={i.description}
                              education={i.education}
                              id={i.id}
                              jobType={i.jobtype}
                              postalCode={i.postalcode}
                              state={i.state}
                              title={i.title}
                              url={i.url}
                            />
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                <Link to="/joblisting">
                  <button className="btn btn-primary">VIEW ALL JOBS</button>
                </Link>
              </div>
            </section>
          </>
        )}

        {/* Fifth block of the home page */}
        {membershipData != "" && 1==0 && (
          <>
            <section className="MembershipSection">
              <div className="container text-center">
                <h3 className="subsecondblockHeading">
                  Membership
                  <span className="textGradient">
                    {" "}
                    <span className="SubHaddingTxt">Plan</span>{" "}
                  </span>
                </h3>
                <div className="MembershipMainBx">
                  <MembershipCard />
                </div>
              </div>
            </section>
          </>
        )}
        {topEmployer != "" && 1==0 &&(
          <>
            <section className="TopEmployersSection">
              <div className="container text-center">
                <h3 className="subsecondblockHeading">
                  Top
                  <span className="textGradient">
                    {" "}
                    <span className="SubHaddingTxt">Employers</span>{" "}
                  </span>
                </h3>
                <div className="TopEmployersBx">
                  <div className="row m-0">
                    {topEmployer.map((i) => {
                      return (
                        <>
                          <div className="col-xs-12 col-md-3 col-lg-3 p-0 TopEmployersBxCardBx">
                            <div className="EmployersLogoBx BorderRight BorderBottom">
                              <Link to={`/companyprofile/${i.slug}`}>
                                <img
                                  className="employersLogo"
                                  src={i.profile_image}
                                  alt=""
                                />
                              </Link>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </section>
          </>
        )}

        {/* Sixth block of the home page */}

        {1==0 && (
        <div className="sixthBlock">
          <section className="DownloadAppSection">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-md-6 col-lg-6">
                  <div className="section1text ">
                    <h2 className="">DOWNLOAD OUR APP</h2>
                    <h3>and Find Your Dream Job</h3>

                    <div className="section1button">
                      <Link to="https://play.google.com/store/apps/details?id=ls.lsjobseeker">
                        <img
                          className=""
                          src="/Images/googlePlayButtton.png"
                          alt=""
                        />
                      </Link>
                      <Link to="https://apps.apple.com/us/app/ls-job-seeker-candidate/id1403773426?ls=1">
                        <img
                          className=""
                          src="/Images/appleStoreButton.png"
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6 col-lg-6">
                  <div className="DownloadRightImg">
                    <img
                      className="phoneImage"
                      src="/Images/Phone.png"
                      alt=""
                    />
                    <img className="girlImage" src="/Images/girl.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {bannerDetails != "" && (
            <>
              <div className="section2">
                <div className="row">
                  {bannerDetails.map((i) => {
                    return (
                      <>
                        <div className="col-md-6 col-sm-12">
                          <Link to={i.url}>
                            <img className="flexImage" src={i.image} alt="" />
                          </Link>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
        )}
      </div>
      <Marquee className="marqueeText" pauseOnHover={true}>
        {announcementDetails.map((i) => {
          return (
            <Link to={i.url} className="marqueeLink">
              | {i.name + " "} |
            </Link>
          );
        })}
        {/* <Link to="https://www.google.com" className="marqueeLink">
          {" "}
          Looking For Job? | Looking For Job Portal Script | 50% Off On Packages
          |
        </Link> */}
      </Marquee>
      <Footer />
      {/* </>
      )} */}
    </>
  );
};

export default UserPage;
document.addEventListener("mousemove", parallax);

function parallax(e) {
  this.querySelectorAll(".layer").forEach((layer) => {
    let speed = layer.getAttribute("data-speed");
    let x = (window.innerWidth - e.pageX * speed) / 900;
    let y = (window.innerWidth - e.pageY * speed) / 900;
    layer.style.transform = `translate(${x}px, ${y}px)`;
  });
}
