import React, { useEffect, useState } from "react";
import NavBar from "../element/NavBar";
import Footer from "../element/Footer";
import JobCard from "../element/JobCard";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BaseApi from "../api/BaseApi";
import JobListingCard from "../element/JobListingCard";
import Cookies from "js-cookie";
import MapKey from "../mapkey/Mapkey";


const JobListing = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  // Access individual parameters
  const radius = params.get("radius");
  const locationParam = params.get("location");
  const keyword = params.get("keyword");

  // console.log('Radius:', radius);
  // console.log("Location:", locationParam);

  // let mapKey = Cookies.get("mapKey");

  // const { slug1, slug2 } = useParams();
  const initialLocation = locationParam || "";
  const initialRadius = radius || "";
  const initialKeyword = keyword || "";

  const [filterItem, setFilterItem] = useState({
    keyword: initialKeyword,
    category: "",
    radius: initialRadius,
    location: initialLocation,
  });
  //   console.log(slug1, slug2);

  const [searchData, setSearchData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [searchButton, setSearchButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [homepageSearchData, setHomepageSearchData] = useState([]);

  let homepageFilter;
  var data;

  const getData = async () => {
    try {
      setLoading(true);

      let response;

      if (initialRadius != "" || initialLocation != "" || initialKeyword != "") {
        response = await axios.post(BaseApi + "/getimportjob", {
          radius: initialRadius,
          location: initialLocation,
          keyword: initialKeyword
        });
        // setCategoryData(response.data.response.jobs);
        // console.log(categoryData, "inside radius and location and keyword");
      } 
      // else if (initialRadius) {
      //   response = await axios.post(BaseApi + "/getimportjob", {
      //     radius: initialRadius,
      //   });
      //   // setCategoryData(response.data.response.jobs);
      //   console.log("radius block triggered");
      // } else if (initialLocation) {
      //   // If a keyword is available in the slug, make the API request with the keyword directly.
      //   response = await axios.post(BaseApi + "/getimportjob", {
      //     location: initialLocation,
      //   });
      //   console.log("Location block triggered");
      // } 
      else {
        // If no keyword is available, fetch all job listings.
        response = await axios.post(BaseApi + "/getimportjob");
        // console.log("Global triggered");
      }
      setLoading(false);
      // console.log(response);
      homepageFilter = response.data.response.jobs;
      setCategoryData(response.data.response.jobs);
      setCategoryList(response.data.response.categories);
      // console.log(categoryData, "categoryData from getData");
      // console.log(homepageFilter, "Homepagefilter from getData");
    } catch (error) {
      setLoading(false);
      console.log("Error at new job list");
    }
  };
  setTimeout(() => {
    // console.log(categoryData, "categoryData after 5 seconds");
  }, 5000);

  useEffect(() => {
    getData();
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    // Check if the category_id is being changed
    if (name === "radius") {
      // Validate input to allow only digits and limit length to 1-3 digits
      const isValidInput = /^\d{0,3}$/.test(value);
      if (isValidInput) {
        setFilterItem((prevFilterItem) => ({
          ...prevFilterItem,
          radius: value,
        }));
      }
    } else {
      setFilterItem((prevFilter) => ({
        ...prevFilter,
        [name]: value,
      }));
    }

    // if (name === "category_id") {
    //   // Call the API with the selected category_id
    //   fetchJobListingsByCategory(value);
    // }
    if (filterItem.category === "Any Category") {
      // Include the category_id in the API request
      setFilterItem({ ...filterItem, category: "" }); // Convert to the desired data type if needed
    }
    // console.log(filterItem);
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setSearchButton(true);
    setLoading(true);
    try {
      const response = await axios.post(BaseApi + "/getimportjob", filterItem);
      setSearchData(response.data.response.jobs);
      setLoading(false);
      console.log("Search filter data sent successfully");
    } catch (error) {
      setLoading(false);
      console.log("Couldn't send the search filter data!");
    }
  };

  //   const fetchJobListingsByCategory = async (categoryId) => {
  //     // setLoading(true);
  //     try {
  //       const response = await axios.post(
  //         `https://job-board-software.logicspice.com/job-board-script/public/api/categories/getSubCategory/${categoryId}`
  //       );
  //       setSubCatData(response.data.response);
  //       setLoading(false);
  //       console.log(subCatData);
  //     } catch (error) {
  //       setLoading(false);
  //       console.log("Couldn't get sub category data at listing page");
  //     }
  //   };

  useEffect(() => {
    setTimeout(() => {
      sessionStorage.clear();
    }, 7000);
    window.scrollTo(0, 0);
  }, []);
  // console.log(MapKey);
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 12;

  // Get current jobs to display based on pagination
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = searchButton
    ? searchData.slice(indexOfFirstJob, indexOfLastJob)
    : categoryData.slice(indexOfFirstJob, indexOfLastJob);

  // Function to handle pagination page change
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const [autocompleteService, setAutocompleteService] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    // Load Google Maps AutocompleteService after component mounts
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${MapKey}&libraries=places`;
    
    script.onload = () => {
      setAutocompleteService(
        new window.google.maps.places.AutocompleteService()
      );
      // console.log(autocompleteService);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleLocationChange = (e) => {
    const { value } = e.target;
    setSuggestionTaken(false);
    if (value == "") {
      setSuggestionTaken(true);
    }

    setFilterItem((prevFilter) => ({
      ...prevFilter,
      location: value,
    }));

    if (autocompleteService) {
      // Call Google Maps Autocomplete API
      autocompleteService.getPlacePredictions(
        {
          input: value,
          types: [], // Restrict to cities if needed
        },
        (predictions, status) => {
          if (status === "OK" && predictions) {
            setSuggestions(
              predictions.map((prediction) => prediction.description)
            );
          } else {
            setSuggestions([]);
          }
        }
      );
    }
    if (filterItem.location === "") {
      setSuggestions([]);
    }
  };
  const [suggestionTaken, setSuggestionTaken] = useState(false);

  const handleSuggestionClick = (suggestion) => {
    // Update the input value with the clicked suggestion
    handleLocationChange({ target: { name: "location", value: suggestion } });

    setSuggestionTaken(true);
    // Clear the suggestions
    setSuggestions([]);
    // console.log(filterItem);
  };

  const handleClear = (e) => {
    e.preventDefault();
    setFilterItem({
      ...filterItem,
      keyword: "",
      category: "",
      location: "",
      radius: "",
    });

    // Clear URL parameters
    const url = new URL(window.location.href);

    // Remove query parameters
    url.searchParams.delete("keyword");
    url.searchParams.delete("category");
    url.searchParams.delete("location");
    url.searchParams.delete("radius");

    // Replace the current URL with the updated one
    window.history.replaceState({}, "", url.toString());
  };


  console.log(categoryData , 'category date');
  console.log(currentJobs , 'current jobs');
  console.log(searchButton , 'search button');
  console.log(typeof(categoryList) , 'categorylist')

  return (
    <>
      <NavBar />
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <div className="SJPSection1">
            <form>
              <div className="joblistingForm">
                <div className="searchItems me-2">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={filterItem.keyword}
                    name="keyword"
                    placeholder="Keyword"
                    onChange={handleChange}
                  />
                </div>
                <div className="searchItems me-2">
                  <select
                    className="form-select text-muted"
                    aria-label="Default select example"
                    value={filterItem.category}
                    name="category"
                    onChange={handleChange}
                  >
                    <option value={""}>Any Category</option>
                    { typeof(categoryList) != 'undefined' ? (
                    categoryList.map((i, id) => {
                      return (
                        <option key={i.id} value={i.id}>
                          {i.category}
                        </option>
                      );
                    })):""}
                  </select>
                </div>

                {/* <div className="searchItems me-2">
                  <select
                    className="form-select text-muted"
                    aria-label="Default select example"
                    value={filterItem.subcategory_id}
                    name="subcategory_id"
                    onChange={handleChange}
                  >
                    <option value={""}>Sub Category</option>
                    {subCatData.map((i, id) => {
                      return (
                        <option key={i.id} value={i.id}>
                          {i.name}
                        </option>
                      );
                    })}
                  </select>
                </div> */}
                <div className="searchItems me-2">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Location"
                    name="location"
                    value={filterItem.location}
                    onChange={handleLocationChange}
                  />
                  {suggestions.length > 0 && (
                    <div
                      className="suggestionsSmall"
                      style={{ display: suggestionTaken ? "none" : "" }}
                    >
                      <ul className="locationDropdown">
                        {suggestions.map((suggestion, index) => (
                          <div key={index} className="suggestion-item">
                            <li
                              onClick={() => handleSuggestionClick(suggestion)}
                            >
                              <div className="eachLocation">
                                <div className="locationIcon">
                                  <LocationOnIcon fontSize="small" />
                                </div>{" "}
                                <div className="locationSuggestion">
                                  {suggestion}
                                </div>
                              </div>
                            </li>
                          </div>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="searchItems me-2">
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    value={filterItem.radius}
                    name="radius"
                    placeholder="Radius (Miles)"
                    onChange={handleChange}
                  />
                </div>
                <div className="jobListingActionButton">
                  <button
                    type="submit"
                    className="searchItems btn jobListingActionButton1"
                    onClick={handleClick}
                  >
                    FIND JOBS
                  </button>
                  <button
                    type="submit"
                    className="searchItems btn jobListingActionButton2"
                    onClick={(e) => handleClear(e)}
                  >
                    CLEAR
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="SJPSection2 container">
            <div className="FBitem">
              <div className="row">
                {searchButton ? (
                  currentJobs.length > 0 ? (
                    currentJobs?.map((i) => (
                      <div className="col-md-6 col-lg-4">
                        <JobListingCard
                          category={i.category}
                          city={i.city}
                          company={i.company}
                          country={i.country}
                          date={i.date}
                          description={i.description}
                          education={i.education}
                          id={i.id}
                          jobType={i.jobtype}
                          postalCode={i.postalcode}
                          state={i.state}
                          title={i.title}
                          url={i.url}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="col-12">
                      <h3 className="text-center">
                        There are no jobs matching your search criteria.
                      </h3>
                      <h6 className="text-muted text-center mb-5 mt-3">
                        Please search with other options.
                      </h6>
                    </div>
                  )
                ) : categoryData && categoryData.length > 0 ? (
                  currentJobs.map((i) => {
                    return (
                      <div className="col-md-6 col-lg-4">
                        <JobListingCard
                          category={i.category}
                          city={i.city}
                          company={i.company}
                          country={i.country}
                          date={i.date}
                          description={i.description}
                          education={i.education}
                          id={i.id}
                          jobType={i.jobtype}
                          postalCode={i.postalcode}
                          state={i.state}
                          title={i.title}
                          url={i.url}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="col-12">
                    <h3 className="text-center">
                      There are no jobs matching for your search criteria
                    </h3>
                    <h6 className="text-muted text-center mb-5 mt-3">
                      Please search with other options
                    </h6>
                  </div>
                )}
              </div>
            </div>

            {/* Custom Pagination */}
          </div>
          <div className="blogPagination">
            <p className="text-muted paginationDetail">
              No. of records {indexOfFirstJob + 1}-
              {Math.min(
                indexOfLastJob,
                searchData.length || categoryData.length
              )}{" "}
              of {searchButton ? searchData.length : categoryData.length}
            </p>
            <div className="blogPaginationButtons">
              <button
                className="navButton1 me-2"
                disabled={currentPage === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                Prev
              </button>
              <button
                className="navButton1"
                disabled={
                  searchButton
                    ? indexOfLastJob >= searchData.length
                    : indexOfLastJob >= categoryData.length
                }
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </button>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default JobListing;
