import React from "react";
import { Link } from "react-router-dom";
import HTMLReactParser from "html-react-parser";

const BlogCard = (props) => {
  return (
    <>
      <div className="blogCard card ">
        <div className="view">
          <Link to={`/dynamicblogpage/${props.slug}`}>
            {props.image ? (
              <img
                className="card-img-top p-2 "
                src={props.image}
                alt="Card cap"
              />
            ) : (
              <img
                className="card-img-top p-2 "
                src="https://mdbootstrap.com/img/Mockups/Lightbox/Thumbnail/img%20(67).webp"
                alt="Card cap"
              />
            )}
          </Link>
        </div>

        <div className="blogCardBody card-body">
        <Link to={`/dynamicblogpage/${props.slug}`}>
          <p className="text-muted mb-2">Posted: {props.created}</p>
          <h4 className="card-title">
            {props.title ? HTMLReactParser(props.title) : ""}
          </h4>
          <p className="card-text text-muted">
            {props.description
              ? HTMLReactParser(props.description.substring(0, 100))
              : ""}
            ...
          </p>
          </Link>
          <Link to={`/dynamicblogpage/${props.slug}`}>
            <p className="mt-3">Learn More &gt;</p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default BlogCard;
