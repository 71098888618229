import React, { useEffect } from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";

const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
    <>
      <NavBar />
      <div className="privacyPolicy">
        <div className="text-center PPSection1">
          <h1 className="">Terms & Conditions</h1>
          <h6 className="text-muted fw-normal">
            {" "}
            <Link to="/" style={{ color: "grey" }}>
              Home
            </Link>{" "}
            /Terms & Conditions
          </h6>
        </div>
        <div className="container">
          <div className="row">
            <div className="">
              <div className="upperPart">
                <p>
                  Thanks for choosing LS Employer ("LS Employer", "we", "us",
                  "our"). By using the LS Employer website or mobile
                  application, including by purchasing Plans, you are entering
                  into a binding contract with our local company in your country
                  of residence ("India") if applicable or the company listed in
                  the chart.
                </p>

                <p className="mt-4">
                  Your agreement with us includes these Terms and Conditions of
                  Use. If you don't agree with these Terms, then please don't
                  use the Service.
                </p>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h2 className="text-center pb-4 pt-2">
                    Send us a
                    
                    <span className="textGradient">
                      {" "}
                      <span className="SubHaddingTxt"> Message</span>
                    </span>
                  </h2>
                  <form>
                    <div className="mb-4">
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        // value={faqForm.name}
                        placeholder="Name*"
                        // onChange={(e) =>
                        //   setFaqForm({ ...faqForm, name: e.target.value })
                        // }
                      />
                    </div>
                    <div className="mb-4">
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        // value={faqForm.email}
                        placeholder="Email*"
                        // onChange={(e) =>
                        //   setFaqForm({ ...faqForm, email: e.target.value })
                        // }
                      />
                    </div>
                    <div className="mb-4">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        // value={faqForm.subject}
                        // onChange={(e) =>
                        //   setFaqForm({ ...faqForm, subject: e.target.value })
                        // }
                      >
                        <option>Please Select</option>
                        <option value="1">General Query</option>
                        <option value="2">Personal Feedback</option>
                        <option value="3">Pre Sale Question</option>
                        <option value="3">Support Issue</option>
                        <option value="3">Refund Issue</option>
                      </select>
                    </div>
                    <div className="mb-4">
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                        // value={faqForm.message}
                        // placeholder="Message*"
                        // onChange={(e) =>
                        //   setFaqForm({ ...faqForm, message: e.target.value })
                        // }
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      className="btn w-100"
                      //   onClick={handleClick}
                    >
                      SEND MESSAGE
                    </button>
                  </form>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsConditions;
