import React, { useEffect, useState } from "react";
import Footer from "../element/Footer";
import { Link } from "react-router-dom";
import NavBar from "../element/NavBar";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";

const HowItWorks = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const newErrors = {};

      if (formData.name === "") {
        newErrors.name = "Name is required";
      }
      if (formData.email === "") {
        newErrors.email = "Email is required";
      } else if (!isValidEmail(formData.email)) {
        newErrors.email = "Invalid email format";
      }
      if (formData.subject === "") {
        newErrors.subject = "Subject is required";
      }
      if (formData.message === "") {
        newErrors.message = "Message is required";
      }
      if (!isCaptchaVerified) {
        newErrors.captcha = "Please verify captcha";
      }

      setErrors(newErrors);

      // Function to validate email format
      function isValidEmail(email) {
        // Use a regular expression to validate email format
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
      }

      if (Object.keys(newErrors).length === 0) {
        if (isCaptchaVerified) {
          const response = await axios.post(
            BaseApi + "/page/contact-us",
            formData
          );
          if (response.data.status === 200) {
            Swal.fire({
              title: "Details submitted. We will contact you shortly!",
              icon: "success",
              confirmButtonText: "Close",
            });
            setFormData({...formData,
              name: "",
              email: "",
              subject: "",
              message: "",
            })
          } else if (response.data.status === 500) {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          } else {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        } 
      }
    } catch (error) {
      Swal.fire({
        title: "Failed",
        text: "Could not submit your details. Please try again later!",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
    console.log(formData);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavBar />
      <div className="privacyPolicy">
        <div className="text-center PPSection1">
          <h1 className="">How It Works</h1>
          <h6 className="text-muted fw-normal">
            {" "}
            <Link to="/" style={{ color: "grey" }}>
              Home
            </Link>{" "}
            /How It Works
          </h6>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="upperPart">
                <p>Below are the steps describing our flow of work: </p>

                <p className="mt-3">
                  1. After registering and loging into the app you can access
                  access application functioanlities from dashbaord or by the
                  side menu.
                </p>
                <p className="mt-3">
                  2. After posting a job all the filtered candidates will be
                  notified.
                </p>
                <p className="mt-3">
                  3. When any user will apply on any job, you will be notified
                  through the app.
                </p>
                <p className="mt-3">
                  4. You can check the list of all the candidates and can change
                  the status of there application status from the app.
                </p>
                <p className="mt-3">
                  5. You can select one or more candidates at a time and send an
                  email to them.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card">
                <div className="card-body">
                  <h2 className="text-center pb-4 pt-2">
                    Send us a
                    <span className="textGradient">
                      {" "}
                      <span className="SubHaddingTxt"> Message</span>
                    </span>
                  </h2>
                  <form>
                    <div className="mb-4">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.name && "input-error"
                        }`}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        name="name"
                        value={formData.name}
                        placeholder="Name*"
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <div className="text-danger">{errors.name}</div>
                      )}
                    </div>
                    <div className="mb-4">
                      <input
                        type="email"
                        className={`form-control ${
                          errors.email && "input-error"
                        }`}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        name="email"
                        value={formData.email}
                        placeholder="Email*"
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <div className="text-danger">{errors.email}</div>
                      )}
                    </div>
                    <div className="mb-4">
                      <select
                        className={`form-select ${
                          errors.subject && "input-error"
                        }`}
                        aria-label="Default select example"
                        value={formData.subject}
                        name="subject"
                        onChange={handleChange}
                      >
                        <option>Please Select</option>
                        <option value="1">General Query</option>
                        <option value="2">Personal Feedback</option>
                        <option value="3">Pre Sale Question</option>
                        <option value="3">Support Issue</option>
                        <option value="3">Refund Issue</option>
                      </select>
                      {errors.subject && (
                        <div className="text-danger">{errors.subject}</div>
                      )}
                    </div>
                    <div className="mb-4">
                      <textarea
                        className={`form-control ${
                          errors.message && "input-error"
                        }`}
                        id="exampleFormControlTextarea1"
                        rows="5"
                        name="message"
                        value={formData.message}
                        placeholder="Message*"
                        onChange={handleChange}
                      ></textarea>
                      {errors.message && (
                        <div className="text-danger">{errors.message}</div>
                      )}
                    </div>
                    <div class="reCaptchaLogin mb-4">
                      <ReCAPTCHA
                        sitekey="6LfHQPwoAAAAACFSrSeEB7GtlkCF68Ly_RyhAtnF"
                        onChange={(value) => setIsCaptchaVerified(value)}
                      />
                      {errors.captcha && (
                        <div className="text-danger">{errors.captcha}</div>
                      )}
                    </div>
                    <button
                      type="submit"
                      className="btn w-100"
                      onClick={handleClick}
                    >
                      SEND MESSAGE
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HowItWorks;
