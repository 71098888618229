import React, { useEffect, useState } from "react";
import Footer from "../element/Footer";
import JSSidebar from "./JSSidebar";
import NavBar from "../element/NavBar";
import axios from "axios";
import ApiKey from "../api/ApiKey";
import BaseApi from "../api/BaseApi";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const JSManageAlerts = () => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState([]);
  const tokenKey = Cookies.get("tokenClient");

  const navigate = useNavigate();

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + "/alerts/index",
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      setLoading(false);
      setAlert(response.data.response.alerts);
      console.log(alert);
    } catch (error) {
      setLoading(false);
      console.log("Cannot get payment data");
    }
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/jobseekerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  const handleClick = async (slug) => {
    console.log(slug);
    if (window.confirm("Do you want to delete this alert?")) {
      try {
        await axios.post(
          BaseApi + `/alerts/delete/${slug}`,
          null, // Pass null as the request body if not required
          {
            headers: {
              "Content-Type": "application/json",
              key: ApiKey,
              token: tokenKey,
            },
          }
        );
        getData();
        console.log("Record deleted successfully!");
      } catch (error) {
        console.log("Cannot delete!");
      }
    }
  };

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const alertsPerPage = 5;

  // Get current jobs to display based on pagination
  const indexOfLastAlert = currentPage * alertsPerPage;
  const indexOfFirstAlert = indexOfLastAlert - alertsPerPage;
  const currentData = alert
    ? alert.slice(indexOfFirstAlert, indexOfLastAlert)
    : alert.slice(indexOfFirstAlert, indexOfLastAlert);

  // Function to handle pagination page change
  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <NavBar />
      <div className="container paymentHistory">
        <div className="row">
          <div className="col-lg-3 col-md-3">
            <JSSidebar />
          </div>
          {loading ? (
            <div className="loader-container"></div>
          ) : (
            <>
              <div
                className="col-lg-9 col-md-9 mb-5"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div className="PHHeader">
                  <div className="JSMASection2">
                    <div className="d-flex JSManageAlertHeader PageHeader">
                      <img
                        src="/Images/jobseekerSide/Manage-Alerts2.png"
                        alt=""
                      />
                      <h3 className="ms-1" style={{ color: "#ecd4f4" }}>
                        Manage Alert
                      </h3>
                    </div>
                    <Link
                      to="/alerts/add"
                      className="btn btn-primary button1 JSMAHeaderButton"
                    >
                      ADD ALERT
                    </Link>
                  </div>
                  <div className="PHBody mt-5">
                    <table className="table">
                      <thead>
                        <tr className="table-active TrFirst">
                          <th className="" scope="col p-3">
                            Sr No.
                          </th>
                          <th className="" scope="col p-3">
                            Locaion
                          </th>
                          <th className="" scope="col p-3">
                            Designation
                          </th>
                          <th className="" scope="col p-3">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.map((i, index) => {
                          return (
                            <tr className="TrDefoult">
                              <td className="">{index + 1}</td>
                              <td className="">{i.location}</td>
                              <td className="">{i.designation}</td>
                              <td className="TrActions">
                                <Link
                                  to={`/alerts/edit/${i.slug}`}
                                  className="btn btn-primary"
                                >
                                  <i class="fa-solid fa-pen-to-square"></i>
                                </Link>
                                <button
                                  onClick={() => handleClick(i.slug)}
                                  className="btn btn-secondary"
                                >
                                  <i class="fa-solid fa-trash"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="JSmanageAlertPagination">
                  <p className="text-muted empPaginationData">
                    No. of records {indexOfFirstAlert + 1}-
                    {Math.min(indexOfLastAlert, alert.length)} of{" "}
                    {alert ? alert.length : alert.length}
                  </p>
                  {/* Custom Pagination */}
                  <div className="d-flex justify-content-center empPaginationButton">
                    <button
                      className="navButton1 me-2"
                      disabled={currentPage === 1}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Prev
                    </button>
                    <button
                      className="navButton1"
                      disabled={
                        alert
                          ? indexOfLastAlert >= alert.length
                          : indexOfLastAlert >= alert.length
                      }
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default JSManageAlerts;
