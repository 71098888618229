import axios from "axios";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import Swal from "sweetalert2";
import PersonIcon from "@mui/icons-material/Person";
import Cookies from "js-cookie";

const NavBar = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [dynamicBlogActive, setDynamicBlogActive] = useState(false);

  const [login, setLogin] = useState(false);
  const [userName, setUserName] = useState();
  const [userType, setUserType] = useState();

  const [navLogo, setNavLogo] = useState();
  let siteLogo = Cookies.get("siteLogo");

  // const getData = async () => {
  //   try {
  //     const response = await axios.get(BaseApi + "/getconstant");
  //     setNavLogo(response.data.response.site_logo);
  //     Cookies.set("siteLogo",navLogo);
  //   } catch (error) {
  //     console.log("Error getting navbar logo information!");
  //   }
  // };
  useEffect(() => {
    // getData();
    // window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const url = window.location.href;

    if (url.includes("dynamicblogpage")) {
      // console.log("The URL contains 'dynamicblogpage'.");
      setDynamicBlogActive(true);
      // You can add your logic here if the condition is met.
    } else {
      setDynamicBlogActive(false);
      // console.log("The URL does not contain 'dynamicblogpage'.");
      // You can add an alternative logic here if the condition is not met.
    }
  }, []);

  // const tokenKey = sessionStorage.getItem("token");
  const navSetter = () => {
    if (tokenKey) {
      setUserName(Cookies.get("fname"));
      setUserType(Cookies.get("user_type"));
      setLogin(true);
    }
  };

  let fname = Cookies.get("fname");

  const tokenKey = Cookies.get("tokenClient");

  useEffect(() => {
    navSetter();
  }, []);

  const handleLogOut = async () => {
    try {
      const confirmationResult = await Swal.fire({
        title: "Logout?",
        text: "Do you want to logout?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      if (confirmationResult.isConfirmed) {
        const response = await axios.post(BaseApi + "/users/logout", null, {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        });
        // sessionStorage.clear();
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        window.location.reload();
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Logout successfull!",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Could not logout!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Cannot log out!");
    }
  };

  const handleClick = (navItem) => {
    setActiveItem(navItem);
  };

  return (
    <Navbar expand="lg" className="defaultnavbar">
      <Container className="">
        <NavLink to="/">
          <Navbar.Brand>
            {siteLogo && (
              <img className="frontendNavLogo" src={siteLogo} alt="Logo" />
            )}
            {!siteLogo && (
              <img
                className="frontendNavLogo"
                src="/Images/logo.png"
                alt="LS Logo"
              />
            )}
            {/* <img
              className="frontendNavLogo"
              src="/Images/logo.png"
              alt="LS Logo"
            /> */}
          </Navbar.Brand>
        </NavLink>

        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="navbardefault">
          <Nav
            className="ms-auto my-2 my-lg-0 navigation"
            navbarScroll
            style={{ fontSize: "18px" }}
          >
            <NavLink
              to="/"
              exact
              activeClassName="active" // Add the "active" class when the link is active
              className="navitems"
            >
              Home
            </NavLink>
            <NavLink
              to="/aboutus"
              activeClassName="active" // Add the "active" class when the link is active
              className="navitems"
            >
              About Us
            </NavLink>
            {userType === "recruiter" && (
              <NavLink
                to="/candidates/listing"
                activeClassName="active" // Add the "active" class when the link is active
                className="navitems"
              >
                Jobseekers
              </NavLink>
            )}

            <NavLink
              to="/how-it-works"
              activeClassName="active" // Add the "active" class when the link is active
              className="navitems"
            >
              How It Works
            </NavLink>

            <NavLink
              to="/blog"
              activeClassName="active"
              className={`navitems ${
                dynamicBlogActive && "navbardefault active"
              }`}
            >
              Blog
            </NavLink>

            <NavLink
              to="/faq"
              activeClassName="active" // Add the "active" class when the link is active
              className="navitems"
            >
              FAQs
            </NavLink>
            <NavLink
              to="/contact"
              activeClassName="active" // Add the "active" class when the link is active
              className="navitems"
            >
              Contact Us
            </NavLink>
            <NavLink
              to="/searchjob"
              activeClassName="active"
              onClick={() => setSearchActive(true)} // Set the active state manually
              className="SearchIcon"
            >
              <i>
                <img
                  className="ms-3 me-2"
                  src="/Images/searchnavicon.png"
                  alt="LS Logo"
                />
              </i>
            </NavLink>

            {login ? (
              <>
                {userType === "recruiter" && (
                  <>
                    <NavLink
                      to="/user/myprofile"
                      activeClassName="active" // Add the "active" class when the link is active
                      className="navitems"
                    >
                      <PersonIcon />

                      {userName}
                    </NavLink>
                    <Link
                      onClick={handleLogOut}
                      activeClassName="" // Add the "active" class when the link is active
                      className="navitems"
                    >
                      Logout
                    </Link>
                  </>
                )}
                {userType === "candidate" && (
                  <>
                    <NavLink
                      to="/candidates/myaccount"
                      activeClassName="active" // Add the "active" class when the link is active
                      className="navitems navUsername"
                    >
                      <i className="fa-solid fa-user mx-2"></i>
                      {userName}
                    </NavLink>
                    <Link
                      onClick={handleLogOut}
                      activeClassName="active" // Add the "active" class when the link is active
                      className="navitems"
                    >
                      Logout
                    </Link>
                  </>
                )}
                {fname === "Admin" && (
                  <>
                    <div class="dropdown">
                      <button
                        class="btn navButton1 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Login
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <Link to="/user/employerlogin" class="dropdown-item">
                            Employer Login
                          </Link>
                        </li>
                        <li>
                          <Link to="/user/jobseekerlogin" class="dropdown-item">
                            Jobseeker Login
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div class="dropdown mx-2">
                      <button
                        class="btn navButton2 dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Register
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <Link
                            to="/user/register/employer"
                            class="dropdown-item"
                          >
                            Employer Sign Up
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/user/register/jobseeker"
                            class="dropdown-item"
                          >
                            Jobseeker Sign Up
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div class="dropdown">
                  <button
                    class="btn navButton1 dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Login
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <Link to="/user/employerlogin" class="dropdown-item">
                        Employer Login
                      </Link>
                    </li>
                    <li>
                      <Link to="/user/jobseekerlogin" class="dropdown-item">
                        Jobseeker Login
                      </Link>
                    </li>
                  </ul>
                </div>
                <div class="dropdown mx-2">
                  <button
                    class="btn navButton2 dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Register
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <Link to="/user/register/employer" class="dropdown-item">
                        Employer Sign Up
                      </Link>
                    </li>
                    <li>
                      <Link to="/user/register/jobseeker" class="dropdown-item">
                        Jobseeker Sign Up
                      </Link>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
