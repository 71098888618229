import React, { useEffect, useState } from "react";
import Footer from "../element/Footer";
import JSSidebar from "./JSSidebar";
import NavBar from "../element/NavBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ApiKey from "../api/ApiKey";
import BaseApi from "../api/BaseApi";
import Swal from "sweetalert2";
import Cookies from 'js-cookie';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MapKey from "../mapkey/Mapkey";



const JSEditAlert = () => {
  const { slug } = useParams();

  const [loading, setLoading] = useState(false);
  const [editAlert, setEditAlert] = useState({
    location: "",
    designation: "",
  });
  const [errors, setErrors] = useState({
    location: "",
    designation: "",
  });
  const [designationList, setDesignationList] = useState([]);
  const tokenKey = Cookies.get("tokenClient");
  // let mapKey = Cookies.get("mapKey");


  const navigate = useNavigate();


  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/jobseekerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + `/alerts/edit/${slug}`,
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      setLoading(false);
      setEditAlert(response.data.response.alert_detail);
      setDesignationList(response.data.response.designationlList);
      console.log(editAlert);
    } catch (error) {
      setLoading(false);
      console.log("Cannot get designation list of edit alert page");
    }
  };



  const handleClick = async () => {
    console.log(editAlert);
    
      try {
        const newErrors = {};

        if (editAlert.location === "") {
          newErrors.location = "Location is required";
          window.scrollTo(0, 0);
        }
  
        if (editAlert.designation === "") {
          newErrors.designation = "Designation is required";
          window.scrollTo(0, 0);
        }
  
        setErrors(newErrors);
  
        if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          title: "Edit Alert?",
          text: "Do you want to save changes to this alert?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });
        if(confirmationResult.isConfirmed){
          await axios.post(
            BaseApi + `/alerts/edit/${slug}`,
            editAlert, // Pass null as the request body if not required
            {
              headers: {
                "Content-Type": "application/json",
                key: ApiKey,
                token: tokenKey,
              },
            }
          );
          Swal.fire({
            title: "Alert updated successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
          navigate("/alerts/index");
        }
      }
      } catch (error) {
        Swal.fire({
          title: "Could not update alert. Please try after some time!",
          icon: "error",
          confirmButtonText: "Close",
        });
        console.log("Cannot edit alert!");
      }
    
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditAlert((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

    // Code for loading Location

    const [autocompleteService, setAutocompleteService] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
  
    useEffect(() => {
      // Load Google Maps AutocompleteService after component mounts
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${MapKey}&libraries=places`;
      script.onload = () => {
        setAutocompleteService(
          new window.google.maps.places.AutocompleteService()
        );
        console.log(autocompleteService);
      };
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, []);
  
    const handleLocationChange = (e) => {
      const { value } = e.target;
      setSuggestionTaken(false);
      if (value == "") {
        setSuggestionTaken(true);
      }
      if (value != "") {
        setErrors({
          location: ""
        })
      }
  
      setEditAlert((prevFilter) => ({
        ...prevFilter,
        location: value,
      }));
  
      if (autocompleteService) {
        // Call Google Maps Autocomplete API
        autocompleteService.getPlacePredictions(
          {
            input: value,
            types: ["(cities)"], // Restrict to cities if needed
          },
          (predictions, status) => {
            if (status === "OK" && predictions) {
              setSuggestions(
                predictions.map((prediction) => prediction.description)
              );
            } else {
              setSuggestions([]);
            }
          }
        );
      }
      if (editAlert.location === "") {
        setSuggestions([]);
      }
    };
    const [suggestionTaken, setSuggestionTaken] = useState(false);
  
    const handleSuggestionClick = (suggestion) => {
      // Update the input value with the clicked suggestion
      handleLocationChange({ target: { name: "location", value: suggestion } });
  
      setSuggestionTaken(true);
      // Clear the suggestions
      setSuggestions([]);
      // console.log(filterItem);
    };

  return (
    <>
      <NavBar />
      <div className="container changePassword JSEditAlert createJob">
        <div className="row">
          <div className="col-lg-3">
            <JSSidebar />
          </div>
          {loading ? (
            <div className="loader-container"></div>
          ) : (
            <>
              <div
                className="col-lg-9 mb-5"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div className="d-flex mx-3 PageHeader ">
                  <img src="/Images/employerSide/icon9color.png" alt="" />
                  <h3 className="mx-2">Edit Alert</h3>
                </div>
                <form>
                  <div className="mb-5 mt-5">
                    <div class="form-outline mb-5 DashBoardInputBx">
                      <label class="form-label" for="form3Example1">
                        Location <span className="RedStar">*</span>
                      </label>
                      <input
                        type="text"
                        id="form3Example1"
                        className={`form-control ${
                          errors.location && "input-error"
                        }`}
                        placeholder="Location"
                        value={editAlert.location}
                        name="location"
                        onChange={handleLocationChange}
                      />
                      {suggestions.length > 0 && (
                      <div
                        className="suggestions"
                        style={{ display: suggestionTaken ? "none" : "" }}
                      >
                        <ul className="locationDropdown">
                          {suggestions.map((suggestion, index) => (
                            <div key={index} className="suggestion-item">
                              <li
                                onClick={() =>
                                  handleSuggestionClick(suggestion)
                                }
                              >
                                <div className="eachLocation">
                                  <div className="locationIcon">
                                    <LocationOnIcon fontSize="small" />
                                  </div>{" "}
                                  <div className="locationSuggestion">
                                    {suggestion}
                                  </div>
                                </div>{" "}
                              </li>
                            </div>
                          ))}
                        </ul>
                      </div>
                    )}
                      {errors.location && (
                        <div className="text-danger">{errors.location}</div>
                      )}
                    </div>
                    <div class="form-outline mb-5 DashBoardInputBx">
                      <label className="form-label" htmlFor="form3Example3">
                        Designation<span className="RedStar">*</span>
                      </label>
                      <select
                        className={`form-select ${
                          errors.designation && "input-error"
                        }`}
                        aria-label="Default select example"
                        name="designation"
                        value={editAlert.designation}
                        onChange={handleChange}
                      >
                        <option selected value="">Select Designation</option>
                        {designationList.map((i) => {
                          return <option value={i.id}>{i.name}</option>;
                        })}
                      </select>
                      {errors.designation && (
                        <div className="text-danger">{errors.designation}</div>
                      )}
                    </div>
                  </div>
                  <div className="bottomButtons EditAlertButtons">
                  <button
                    type="button"
                    className="btn btn-primary button1"
                    onClick={handleClick}
                  >
                    UPDATE
                  </button>
                  <Link
                    to="/alerts/index"
                    type="button"
                    className="btn btn-primary button2"
                  >
                    GO BACK
                  </Link>
                  </div>
                  
                </form>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default JSEditAlert;
