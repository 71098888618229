import React, { useEffect, useState } from "react";
import NavBar from "../element/NavBar";
import Sidebar from "./Sidebar";
import Footer from "../element/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import PasswordStrengthBar from "react-password-strength-bar";

const ChangePassword = () => {
  const [password, setPassword] = useState({
    old_password: "",
    new_password: "",
    conf_password: "",
  });
  const [errors, setErrors] = useState({
    old_password: "",
    new_password: "",
    conf_password: "",
  });
  const [loading, setLoading] = useState(false);
  const tokenKey = Cookies.get("tokenClient");
  const navigate = useNavigate();

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setPassword((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleClick = async () => {
    try {
      const newErrors = {};

      if (password.old_password === "") {
        newErrors.old_password = "Old Password is required";
        window.scrollTo(0, 0);
      }

      if (password.new_password === "") {
        newErrors.new_password = "New Password is required";
        window.scrollTo(0, 0);
      }
      if (password.conf_password === "") {
        newErrors.conf_password = "Confirm Password is required";
        window.scrollTo(0, 0);
      }
      if (password.new_password) {
        if (password.new_password.length < 8) {
          newErrors.new_password = "Please enter atleast 8 characters";
        }
      }
      if (password.conf_password) {
        if (password.conf_password.length < 8) {
          newErrors.conf_password = "Please enter atleast 8 characters";
        }
      }
      if (password.new_password && password.conf_password) {
        if (password.new_password !== password.conf_password) {
          newErrors.conf_password =
            "New Password and Confirm Password do not match";
        }
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          title: "Update Password",
          text: "Do you want to update password?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });
        if (confirmationResult.isConfirmed) {
          setLoading(true);
          const response = await axios.post(
            BaseApi + "/users/changePassword",
            password,
            {
              headers: {
                "Content-Type": "application/json",
                key: ApiKey,
                token: tokenKey,
              },
            }
          );
          if (response.data.status === 200) {
            setLoading(false);
            Swal.fire({
              title: "Password updated successfully!",
              icon: "success",
              confirmButtonText: "Close",
            });
            navigate("/user/myprofile");
          } else {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        }
      }
      // }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Could not update Password. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Could not change password!");
    }
  };

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/employerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      // getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  return (
    <>
      <NavBar />

      <div className="container changePassword editProfile">
        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          {loading ? (
            <div className="loader-container"></div>
          ) : (
            <>
              <div
                className="col-lg-9 mb-5"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div className="d-flex mx-3 PageHeader">
                  <img src="/Images/employerSide/icon9color.png" alt="" />
                  <h3 className="mx-2">Change Your Password</h3>
                </div>
                <form>
                  <div className="mb-5 mt-5">
                    <div class="form-outline mb-5 DashBoardInputBx">
                      <label class="form-label" for="form3Example1">
                        Old Password <span className="RedStar">*</span>
                      </label>
                      <input
                        type="password"
                        id="form3Example1"
                        className={`form-control ${
                          errors.old_password && "input-error"
                        }`}
                        placeholder="Old Password"
                        value={password.old_password}
                        name="old_password"
                        onChange={handleChange}
                      />
                      {errors.old_password && (
                        <div className="text-danger">{errors.old_password}</div>
                      )}
                    </div>
                    <div class="form-outline mb-5 DashBoardInputBx">
                      <label class="form-label" for="form3Example3">
                        New Password <span className="RedStar">*</span>
                      </label>
                      <input
                        type="password"
                        id="form3Example3"
                        className={`form-control ${
                          errors.new_password && "input-error"
                        }`}
                        placeholder="New Password"
                        value={password.new_password}
                        name="new_password"
                        onChange={handleChange}
                      />
                      {errors.new_password && (
                        <div className="text-danger">{errors.new_password}</div>
                      )}
                      {password.new_password && (
                        <PasswordStrengthBar password={password.new_password} />
                      )}
                    </div>
                    <div class="form-outline mb-5 DashBoardInputBx">
                      <label class="form-label" for="form3Example3">
                        Confirm Password <span className="RedStar">*</span>
                      </label>
                      <input
                        type="password"
                        id="form3Example3"
                        className={`form-control ${
                          errors.conf_password && "input-error"
                        }`}
                        placeholder="Confirm Password"
                        value={password.conf_password}
                        name="conf_password"
                        onChange={handleChange}
                      />
                      {errors.conf_password && (
                        <div className="text-danger">
                          {errors.conf_password}
                        </div>
                      )}
                      {password.conf_password && (
                        <PasswordStrengthBar
                          password={password.conf_password}
                        />
                      )}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-primary button1 mx-3"
                    onClick={handleClick}
                  >
                    UPDATE PASSWORD
                  </button>
                  <button type="button" className="btn btn-primary button2">
                    CANCEL
                  </button>
                </form>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ChangePassword;
