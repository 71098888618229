import React, { useEffect, useState } from "react";
import NavBar from "../element/NavBar";
import Sidebar from "./Sidebar";
import Footer from "../element/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import Swal from "sweetalert2";
import Cookies from 'js-cookie';

const ChangeLogo = () => {
  const [loading, setLoading] = useState(false);
  const tokenKey = Cookies.get("tokenClient");
  const navigate = useNavigate();
  const [photo, setPhoto] = useState({
    company_logo: "",
    profile_image: "",
  });
  const [errors, setErrors] = useState({
    company_logo: "",
    profile_image: "",
  });
  const [logo, setLogo] = useState("");
  const [establishmentPhoto, setEstablishmentPhoto] = useState("");

  useEffect(() => {
    // Check if tokenKey is not present
    if (!tokenKey) {
      // Redirect to the home page
      navigate("/user/employerlogin");
    } else {
      // TokenKey is present, fetch data or perform other actions
      getData();
      window.scrollTo(0, 0);
    }
  }, [tokenKey, navigate]);

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + "/users/uploadPhoto",
        null, // Pass null as the request body if not required
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      setLoading(false);
      setPhoto(response.data.response);
      setLogo(response.data.response.logo);
      setEstablishmentPhoto(response.data.response.image);
      console.log(photo);
      console.log(logo);
      console.log(establishmentPhoto);
    } catch (error) {
      setLoading(false);
      console.log("Cannot get profile photo data");
    }
  };

  const handleClick = async () => {
    try {
      const newErrors = {};

      if (photo.company_logo === "") {
        newErrors.company_logo = "Company Logo is required";
        window.scrollTo(0, 0);
      }
      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
        const confirmationResult = await Swal.fire({
          title: "Upload Logo",
          text: "Do you want to upload this logo?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
        });
        if (confirmationResult.isConfirmed) {
          setLoading(true);
          const response = await axios.post(
            BaseApi + "/users/uploadPhoto",
            photo,
            {
              headers: {
                "Content-Type": "application/json",
                key: ApiKey,
                token: tokenKey,
              },
            }
          );
          getData();
          setLoading(false);
          Swal.fire({
            title: "Logo updated successfully!",
            icon: "success",
            confirmButtonText: "Close",
          });
          window.scrollTo(0, 0);
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Could not update logo. Please try after some time!",
        icon: "error",
        confirmButtonText: "Close",
      });
      console.log("Could not update photo!");
    }
  };

  const handleFileUpload1 = async (e) => {
    const {name} = e.target;
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    console.log(base64);
    setPhoto({ ...photo, company_logo: base64 });
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleFileUpload2 = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    console.log(base64);
    setPhoto({ ...photo, profile_image: base64 });
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavBar />

      <div className="container changeLogo editProfile">
        <div className="row">
          <div className="col-lg-3">
            <Sidebar />
          </div>
          {loading ? (
            <div className="loader-container"></div>
          ) : (
            <>
              <div
                className="col-lg-9 mb-5 CLPanelRight"
                style={{
                  borderLeft: "2px solid #e6e8e7",
                  borderRight: "2px solid #e6e8e7",
                }}
              >
                <div className="d-flex PageHeader">
                  <img src="/Images/employerSide/icon10color.png" alt="" />
                  <h3 className="mx-2">Change Logo</h3>
                </div>
                <div className="ChangePhotoEmployers mb-4 mt-5">
                  {logo ? (
                    <img src={logo} alt="profile" />
                  ) : (
                    <img src="/Images/jobseekerSide/dummy-profile.png" alt="" />
                  )}
                </div>
                <form>
                  <div className="mb-4 mt-5">
                    <div class="mb-5 DashBoardInputBx">
                      <label for="formFile" class="form-label">
                        New Logo<span className="RedStar">*</span>
                      </label>
                      <input
                        className={`form-control ${
                          errors.company_logo && "input-error"
                        }`}
                        type="file"
                        id="formFile"
                        lable="Image"
                        name="profile_image"
                        accept=".jpeg, .png, .jpg"
                        onChange={(e) => handleFileUpload1(e)}
                      />
                      {errors.company_logo && (
                        <div className="text-danger">{errors.company_logo}</div>
                      )}
                      <div id="emailHelp" class="form-text">
                        Supported File Types: gif, jpg, jpeg, png (Max. 10MB).
                        Min file size 250 X 250 pixels.
                      </div>
                    </div>
                  </div>
                </form>
                <div className="ChangePhotoEmployers mb-4 mt-5">
                  {establishmentPhoto ? (
                    <img src={establishmentPhoto} alt="profile" />
                  ) : (
                    <img src="/Images/jobseekerSide/dummy-profile.png" alt="" />
                  )}
                </div>
                <form>
                  <div className="mb-4 mt-5">
                    <div class="mb-5 DashBoardInputBx">
                      <label for="formFile" class="form-label">
                        Upload Establishment Photo
                        
                      </label>
                      <input
                        class="form-control"
                        type="file"
                        id="formFile"
                        lable="Image"
                        name="profile_image"
                        accept=".jpeg, .png, .jpg"
                        onChange={(e) => handleFileUpload2(e)}
                      />
                      <div id="emailHelp" class="form-text">
                        Supported File Types: jpg, jpeg, png Min file size 1260
                        X 264 pixels.
                      </div>
                    </div>
                  </div>
                  <div className="bottomButtons changeLogoButtons">
                    <button
                      type="button"
                      className="btn btn-primary button1"
                      onClick={handleClick}
                    >
                      UPLOAD
                    </button>
                    <button type="button" className="btn btn-primary button2">
                      CANCEL
                    </button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ChangeLogo;

function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
