import React, { useEffect, useState } from "react";
import NavBar from "../element/NavBar";
import Footer from "../element/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";

const Contact = () => {
  const [contactData, setContactData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const getData = async () => {
    try {
      const response = await axios.get(BaseApi + `/page/contact-us`);
      setContactData(response.data.response.contact_details);
    } catch (error) {
      console.log("Error getting contact information!");
    }
  };
  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const newErrors = {};

      if (formData.name === "") {
        newErrors.name = "Name is required";
      }
      if (formData.email === "") {
        newErrors.email = "Email is required";
      } else if (!isValidEmail(formData.email)) {
        newErrors.email = "Invalid email format";
      }
      if (formData.subject === "") {
        newErrors.subject = "Subject is required";
      }
      if (formData.message === "") {
        newErrors.message = "Message is required";
      }
      if (!isCaptchaVerified) {
        newErrors.captcha = "Please verify captcha";
      }

      setErrors(newErrors);

      // Function to validate email format
      function isValidEmail(email) {
        // Use a regular expression to validate email format
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
      }

      if (Object.keys(newErrors).length === 0) {
        if (isCaptchaVerified) {
          setLoading(true);
          const response = await axios.post(
            BaseApi + "/page/contact-us",
            formData
          );
          setLoading(false);
          if (response.data.status === 200) {
            Swal.fire({
              title: "Details submitted. We will contact you shortly!",
              icon: "success",
              confirmButtonText: "Close",
            });
            setFormData({
              ...formData,
              name: "",
              email: "",
              subject: "",
              message: "",
            });
          } else if (response.data.status === 500) {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          } else {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: "Close",
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Failed",
        text: "Could not submit your details. Please try again later!",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
    console.log(formData);
  };

  return (
    <>
      <div className="contactUs">
        <NavBar />
        {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
        <div className="ContactSection1 text-center">
          <h1>Contact Us</h1>
          <h6 className="text-muted fw-normal">
            {" "}
            <Link to="/" style={{ color: "grey" }}>
              Home
            </Link>{" "}
            /Contact Us
          </h6>
        </div>
        <div className="ContactSection3 container mt-3">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="contactInfo">
                <div className="card mb-3 mt-3">
                  <div className="card-body">
                    <div className="ContactTopHadding">
                      <p className="h4 card-title">Get In Touch</p>
                      <p className="card-body">
                        Alternatively you can also check for the Company{" "}
                        <br></br>
                        email, phone number and address in the official website.
                      </p>
                    </div>
                    <div className="row">
                      <div className="ContactDetails col-sm-12">
                        <i>
                          <img src="/Images/email-icon.png" alt="" />
                        </i>
                        <div className="ContactDetailsBx">
                          <strong>Email:</strong>
                          <span> {contactData.email}</span>
                        </div>
                      </div>
                      <div className="ContactDetails col-sm-12">
                        <i>
                          <img src="/Images/call-icon.png" alt="" />
                        </i>
                        <div className="ContactDetailsBx">
                          <strong>Phone: </strong>
                          <span> {contactData.contact}</span>
                        </div>
                      </div>
                      <div className="ContactDetails col-sm-12">
                        <i>
                          <img src="/Images/location-icon.png" alt="" />
                        </i>
                        <div className="ContactDetailsBx">
                          <strong> Location:</strong>{" "}
                          <span> {contactData.address}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="ContactForm">
                <form>
                  <div className="mb-4">
                    <input
                      type="text"
                      className={`form-control ${errors.name && "input-error"}`}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="name"
                      value={formData.name}
                      placeholder="Name*"
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <div className="text-danger">{errors.name}</div>
                    )}
                  </div>
                  <div className="mb-4">
                    <input
                      type="email"
                      className={`form-control ${
                        errors.email && "input-error"
                      }`}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      name="email"
                      value={formData.email}
                      placeholder="Email*"
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <div className="text-danger">{errors.email}</div>
                    )}
                  </div>
                  <div className="mb-4">
                    <select
                      className={`form-select ${
                        errors.subject && "input-error"
                      }`}
                      aria-label="Default select example"
                      value={formData.subject}
                      name="subject"
                      onChange={handleChange}
                    >
                      <option>Please Select</option>
                      <option value="1">General Query</option>
                      <option value="2">Personal Feedback</option>
                      <option value="3">Pre Sale Question</option>
                      <option value="3">Support Issue</option>
                      <option value="3">Refund Issue</option>
                    </select>
                    {errors.subject && (
                      <div className="text-danger">{errors.subject}</div>
                    )}
                  </div>
                  <div className="mb-4">
                    <textarea
                      className={`form-control ${
                        errors.message && "input-error"
                      }`}
                      id="exampleFormControlTextarea1"
                      rows="3"
                      name="message"
                      value={formData.message}
                      placeholder="Message*"
                      onChange={handleChange}
                    ></textarea>
                    {errors.message && (
                      <div className="text-danger">{errors.message}</div>
                    )}
                  </div>
                  <div class="reCaptchaLogin mb-4">
                    <ReCAPTCHA
                      sitekey="6LfHQPwoAAAAACFSrSeEB7GtlkCF68Ly_RyhAtnF"
                      onChange={(value) => setIsCaptchaVerified(value)}
                    />
                    {errors.captcha && (
                      <div className="text-danger">{errors.captcha}</div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="btn w-100"
                    onClick={handleClick}
                  >
                    SEND MESSAGE
                  </button>
                </form>
                {/* <form class="">
                <div class="row">
                  <div class="col-xs-12 col-md-6 col-lg-6">
                    <div class="input-group mb-4">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.name && "input-error"
                        }`}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        name="name"
                        value={formData.name}
                        placeholder="Name*"
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <div className="text-danger">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div class="col-xs-12 col-md-6 col-lg-6">
                    <div class="input-group mb-4">
                      <input
                        type="email"
                        className={`form-control ${
                          errors.subject && "input-error"
                        }`}
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        name="subject"
                        value={formData.subject}
                        placeholder="Email*"
                        onChange={handleChange}
                      />
                      {errors.subject && (
                        <div className="text-danger">{errors.subject}</div>
                      )}
                    </div>
                  </div>
                </div>

                <div class="input-group mb-4">
                  <input
                    type="email"
                    className={`form-control ${errors.email && "input-error"}`}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    name="email"
                    value={formData.email}
                    placeholder="Email*"
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <div className="text-danger">{errors.email}</div>
                  )}
                </div>
                <div class="input-group mb-4">
                  <textarea
                    className={`form-control ${
                      errors.message && "input-error"
                    }`}
                    id="exampleFormControlTextarea1"
                    rows="3"
                    name="message"
                    value={formData.message}
                    placeholder="Message*"
                    onChange={handleChange}
                  ></textarea>
                  {errors.message && (
                    <div className="text-danger">{errors.message}</div>
                  )}
                </div>
                <div class="input-group mb-4">
                  <ReCAPTCHA
                    sitekey="6Ld8bV8nAAAAAEp24xWlKsVFhVDYlBctFF50MI1x"
                    onChange={handleCaptchaVerify}
                  />
                </div>
                <button class="btn" type="submit" onClick={handleClick}>
                  SUBMIT
                </button>
              </form> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="MapIfream">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3559.0056663398964!2d75.77327287437394!3d26.87156106185979!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5da0662b7eb%3A0xb53d8d49ec204540!2sLogicSpice%20Consultancy%20Private%20Limited!5e0!3m2!1sen!2sin!4v1685081218808!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  title="myFrame"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        </>
      )}
      </div>
    </>
  );
};

export default Contact;
