import React from 'react'
import { Link } from 'react-router-dom'

const Error = () => {
  return (
    <div className='errorPage'>
      <img className='errorImage' src="/Images/errorPage.jpg" alt='Error'/><br />
      <Link to="/" className='navButton1'>Home</Link>
    </div>
    
  )
}

export default Error
