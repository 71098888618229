import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../element/Footer";
import NavBar from "../element/NavBar";
import BaseApi from "../api/BaseApi";

const SiteMap = () => {
  const [loading, setLoading] = useState(false);
  const [siteMapData, setSiteMapData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [jobData, setJobData] = useState([]);


  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(BaseApi + "/sitemap", null);
      setLoading(false);
      setSiteMapData(response);
      setCategoryData(response.data.response.category);
      setJobData(response.data.response.jobs);
      console.log(siteMapData);
    } catch (error) {
      setLoading(false);
      console.log("Cannot get site map data");
    }
  };
  const handleClick = (id) => {
    sessionStorage.setItem("catId", id)
  }

  useEffect(() => {
    getData();
    window.scrollTo(0,0);
  }, []);

  return (
    <>
      <NavBar />
      <div className="blogPageSection1 text-center">
        <h1>Site Map</h1>
        <h6 className="text-muted mt-2">
          {" "}
          <Link to="/" style={{ color: "grey" }}>
            Home
          </Link>{" "}
          /Site Map
        </h6>
      </div>
      <div className="allCategorySection2 container">
        <div className="row">
          {/* {loading ? (
            <div className="loader-container"></div>
          ) : (
            <> */}
              <div className="">
                <div className="card-body Jcard">
                  <h4>
                    <u>Main Pages</u>
                  </h4>
                  <div className="row">
                    <Link to="/" className="col-md-3 eachLink">
                      Home
                    </Link>
                    <Link
                      to="/user/register/jobseeker"
                      className="col-md-3 eachLink"
                    >
                      Jobseeker Register
                    </Link>
                    <Link
                      to="/user/register/employer"
                      className="col-md-3 eachLink"
                    >
                      Employer Register
                    </Link>
                    <Link to="/blog" className="col-md-3 eachLink">
                      Blog
                    </Link>
                    <Link to="/contact" className="col-md-3 eachLink">
                      Contact Us
                    </Link>
                    <Link to="/searchjob" className="col-md-3 eachLink">
                      How It Works
                    </Link>
                    <Link to="/aboutus" className="col-md-3 eachLink">
                      About Us
                    </Link>
                    <Link to="/career-tools" className="col-md-3 eachLink">
                      Career Tools
                    </Link>
                    <Link to="/searchjob" className="col-md-3 eachLink">
                      Career Resources
                    </Link>
                    <Link to="/faq" className="col-md-3 eachLink">
                      FAQ
                    </Link>
                    <Link to="/" className="col-md-3 eachLink">
                      Benefits
                    </Link>
                    <Link
                      to="/terms-and-conditions"
                      className="col-md-3 eachLink"
                    >
                      Terms & Conditions
                    </Link>
                    <Link to="/privacy-policy" className="col-md-3 eachLink">
                      Privacy Policy
                    </Link>
                  </div>
                  <h4 className="mt-5">
                    <u>Categories</u>
                  </h4>
                  <div className="row">
                    {categoryData.map((i) => {
                      return (
                        <>
                          <Link
                            to="/searchjob"
                            onClick={() => handleClick(i.id)}
                            className="col-md-3 eachLink"
                          >
                            {i.name}
                          </Link>
                        </>
                      );
                    })}
                  </div>
                  <h4 className="mt-5">
                    <u>Latest Jobs</u>
                  </h4>
                  <div className="row">
                    {jobData.map((i) => {
                      return (
                        <>
                          <Link
                            to={`/jobdescription/${i.job_slug}/${i.cat_slug}`}
                            className="col-md-3 eachLink"
                          >
                            {i.title}
                          </Link>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            {/* </>
          )} */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SiteMap;
